
    import {defineComponent, getCurrentInstance, onMounted, ref} from "vue";
    import {useRouter} from "vue-router";

    export default defineComponent({
        setup() {
            const {push, currentRoute} = useRouter();
            const internalInstance = getCurrentInstance();
            function handleLogin() {
                internalInstance!.appContext.config.globalProperties.$msal.signIn();
            }

            return { handleLogin };
        },
    });
